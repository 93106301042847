import { Backdrop, CircularProgress, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Filters } from './components/Filters';
import { Box } from './styles';
import { UsersTable } from './components/UsersTable';
import { Pagination, Skeleton } from '@material-ui/lab';
import { Pageable } from '../../types/Pageable';
import { useAppTranslation, useModalVisibility, usePagination } from '../../hooks';
import { useGetAllIdentityProvidersQuery } from '../../config/api/identityProviderEndpoints';
import { IdpUser } from '../../types/IdpUser';
import { useEffect, useState } from 'react';
import { IdentityProvider } from '../../types/IdentityProvider';
import { useSnackbar } from 'notistack';
import {
  useActiveUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useInactiveUserMutation,
  useResendValidationCodeMutation
} from '../../config/api/idpUserEndpoints';
import { ConfirmationModal } from '../../components/ConfirmationModal/Index';
import { SecurityCodeModal } from '../../components/SecurityCodeModal/SecurityCodeModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

interface UsersVisualizationProps {
  useGetAllUsersQuery: typeof useGetAllUsersQuery;
  useActiveUserMutation: typeof useActiveUserMutation;
  useInactiveUserMutation: typeof useInactiveUserMutation;
  useDeleteUserMutation: typeof useDeleteUserMutation;
  userRole: 'ADMIN' | 'OPERATOR';
  messages: {
    'SUBMIT_ERROR': string;
    'INACTIVE_SUCCESS': string;
    'ACTIVE_SUCCESS': string;
  };
}

const UsersVisualization = ({
                              useGetAllUsersQuery,
                              useActiveUserMutation,
                              useInactiveUserMutation,
                              useDeleteUserMutation,
                              messages,
                              userRole,
                            }: UsersVisualizationProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useAppTranslation('USERS_VISUALIZATION');

  /* Initial data */
  const defaultInitialPageableForIdentityProvider: Pageable = {
    pageNumber: 0,
    pageSize: 5000,
    sort: [{ field: 'name', order: 'asc' }],
  };
  const [pageable, handlePageChange] = usePagination({ sort: [{ field: 'name', order: 'asc' }] });
  const { data: identityProviderData } = useGetAllIdentityProvidersQuery(defaultInitialPageableForIdentityProvider);
  const [resendActivationEmail] = useResendValidationCodeMutation();
  const identityProviders = identityProviderData?.content ?? [];

  /* Filter methods */
  const initialFilterState: Partial<IdpUser> = { status: 'ACTIVE', role: userRole };
  const [userFilter, setUserFilter] = useState<Partial<IdpUser>>(initialFilterState);
  const [filterState, setFilterState] = useState<Partial<IdpUser>>(initialFilterState);

  const handleFilterApplyClick = () => {
    setUserFilter({
      name: filterState.name,
      email: filterState.email,
      status: filterState.status,
      identityProvider: filterState.identityProvider,
      role: userRole,
    });
  };

  const handleFilterRemoveClick = () => {
    setFilterState(initialFilterState);
    setUserFilter({
      ...initialFilterState,
    });
  };

  const handleFilterChange = (field: keyof IdpUser, value: string | IdentityProvider | null) => {
    if (field === 'status' && value === 'ALL')
      value = null;

    setFilterState({
      ...filterState,
      [field]: value,
    });
  };

  /* Modal to active or inactive users methods */
  const [inactiveUser] = useInactiveUserMutation();
  const [activeUser] = useActiveUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const inactiveModalState = useModalVisibility();
  const activeModalState = useModalVisibility();
  const deleteModalState = useModalVisibility();
  const securityCodeModalState = useModalVisibility();
  const [isWrongSecurityCode, setIsWrongSecurityCode] = useState<boolean>(false);
  const [selectedOperation, setSelectedOperation] = useState<'active' | 'inactive' | 'delete' | 'resendActivationEmail' | 'resendValidationCode' | undefined>(undefined);
  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
  const [selectUser, setSelectUser] = useState<IdpUser | null>(null);
  const selectedUsername = selectUser?.name ?? '';


  const changeUserStatus = (userActionFunction: typeof inactiveUser | typeof activeUser | typeof deleteUser,
                            closeModalFunction: () => void,
                            securityCode: string,
                            successMsg: string,
                            failedMsg?: { code: number, message: string }) => {
    setShowBackdrop(true);
    userActionFunction([selectUser?.id ?? '', securityCode])
      .unwrap()
      .then(() => {
        closeModalFunction();
        securityCodeModalState.closeModal();
        return enqueueSnackbar(successMsg, { variant: 'success' });
      })
      .catch((e) => {
        console.log(failedMsg);
        if (e.status === 403)
          setIsWrongSecurityCode(true);
        else if (failedMsg && failedMsg.code === e.status)
          return enqueueSnackbar(failedMsg.message, { variant: 'error' });
        else
          return enqueueSnackbar(messages['SUBMIT_ERROR'], { variant: 'error' });
      })
      .finally(() => setShowBackdrop(false));
  };

  const handleConfirmModal = (userActionFunction: typeof inactiveUser | typeof activeUser | typeof deleteUser,
                              closeModalFunction: () => void,
                              securityCode: string,
                              successMsg: string,
                              failedMsg?: { code: number, message: string }) => {
    if (selectUser)
      changeUserStatus(userActionFunction, closeModalFunction, securityCode, successMsg, failedMsg);
  };

  /* Table methods */
  const { data, isSuccess, isFetching } = useGetAllUsersQuery([userFilter, pageable]);
  
  // Avoid an incorrect page after filtering or deleting the last page.
  useEffect(() => {
    if (data && pageable.pageNumber >= data.totalPages) 
      handlePageChange(data.totalPages - 1);
  }, [data, handlePageChange, pageable]);

  const handleActiveUserClick = (user: IdpUser) => {
    setSelectUser(user);
    activeModalState.openModal();
  };

  const handleInactiveUserClick = (user: IdpUser) => {
    setSelectUser(user);
    inactiveModalState.openModal();
  };

  const handleDeleteUserClick = (user: IdpUser) => {
    setSelectUser(user);
    deleteModalState.openModal();
  };

  const handleResendActivationEmailClick = (user: IdpUser, securityCode: string) => {
    setShowBackdrop(true);
    resendActivationEmail([user.id, securityCode])
      .unwrap()
      .then(() => {
        securityCodeModalState.closeModal();
        return enqueueSnackbar(t('RESEND_ACTIVATION_EMAIL_SUCCESS'), { variant: 'success' });
      })
      .catch((e) => {
        if (e.status === 403)
          setIsWrongSecurityCode(true);
        else
          return enqueueSnackbar(messages['SUBMIT_ERROR'], { variant: 'error' });
      })
      .finally(() => setShowBackdrop(false));
  };

  const canActiveUser = (user: IdpUser) => user.status === 'INACTIVE';
  const canInactiveUser = (user: IdpUser) => user.status === 'ACTIVE';
  const canResendActivationEmail = (user: IdpUser) => user.status === 'PENDING_ACTIVATION' || user.status === 'PENDING_VALIDATION';
  const canDeleteUser = (user: IdpUser) => user.status === 'PENDING_VALIDATION';

  const handleSave = (securityCode: string) => {
    if (selectedOperation === 'active')
      handleConfirmModal(activeUser, activeModalState.closeModal, securityCode, messages['ACTIVE_SUCCESS'], {
        code: 406,
        message: t('OPERATOR_LIST:LIMIT_OPERATORS_REACHED')
      });
    else if (selectedOperation === 'inactive')
      handleConfirmModal(inactiveUser, inactiveModalState.closeModal, securityCode, messages['INACTIVE_SUCCESS']);
    else if (selectedOperation === 'delete')
      handleConfirmModal(deleteUser, deleteModalState.closeModal, securityCode, t('DELETE_SUCCESS', { username: selectedUsername }));
    else if (selectedOperation === 'resendActivationEmail')
      handleResendActivationEmailClick(selectUser as IdpUser, securityCode);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {!isFetching ?
            <Filters
              filterState={filterState}
              identityProviders={identityProviders}
              handleFilterChange={handleFilterChange}
              handleFilterApplyClick={handleFilterApplyClick}
              handleFilterRemoveClick={handleFilterRemoveClick}
            />
            :
            <Box>
              <Skeleton variant='rect' height={300} />
            </Box>
          }
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            {!isFetching ?
              <>
                <UsersTable
                  requestSuccess={isSuccess}
                  users={data?.content ?? []}
                  handleActiveUser={handleActiveUserClick}
                  handleInactiveUser={handleInactiveUserClick}
                  handleResendActivationEmail={(idpUser: IdpUser) => {
                    setSelectUser(idpUser);
                    setSelectedOperation('resendActivationEmail');
                    securityCodeModalState.openModal();
                  }}
                  handleDeleteUser={handleDeleteUserClick}
                  canActiveUser={canActiveUser}
                  canInactiveUser={canInactiveUser}
                  canResendActivationEmail={canResendActivationEmail}
                  canDeleteUser={canDeleteUser}
                />
                <Pagination
                  style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
                  count={data ? data.totalPages : 1}
                  page={pageable.pageNumber + 1}
                  onChange={(_, page) => handlePageChange(page - 1)} />
              </>
              :
              <Skeleton variant='rect' height={500} />
            }
          </Box>
        </Grid>
      </Grid>
      <ConfirmationModal open={inactiveModalState.isVisible}
                         title={t('INACTIVE_TITLE', { username: selectedUsername })}
                         body={t('INACTIVE_MESSAGE', { username: selectedUsername })}
                         onClose={inactiveModalState.closeModal}
                         onConfirm={() => {
                           setSelectedOperation('inactive');
                           securityCodeModalState.openModal();
                         }} />
      <ConfirmationModal open={activeModalState.isVisible}
                         title={t('ACTIVE_TITLE', { username: selectedUsername })}
                         body={t('ACTIVE_MESSAGE', { username: selectedUsername })}
                         onClose={activeModalState.closeModal}
                         onConfirm={() => {
                           setSelectedOperation('active');
                           securityCodeModalState.openModal();
                         }} />
      <ConfirmationModal open={deleteModalState.isVisible}
                         title={t('DELETE_TITLE', { username: selectedUsername })}
                         body={t('DELETE_MESSAGE', { username: selectedUsername })}
                         onClose={deleteModalState.closeModal}
                         onConfirm={() => {
                           setSelectedOperation('delete');
                           securityCodeModalState.openModal();
                         }} />
      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <SecurityCodeModal
        open={securityCodeModalState.isVisible}
        handleConfirm={handleSave}
        isWrongSecurityCode={isWrongSecurityCode}
        handleClose={() => {
          securityCodeModalState.closeModal();
          setIsWrongSecurityCode(false);
        }}
      />
    </>
  );
};

export default UsersVisualization;