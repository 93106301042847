import Footer from 'components/Footer/Index';
import { Button, FormControl, Grid, TextField, Tooltip } from '@material-ui/core';

import { Actions, Atributo, Atributos, Box, Container, Wrapper } from './styles';
import { FaCheck, FaRegQuestionCircle, FaWindowClose } from 'react-icons/fa';
import { Attribute } from './components/Attribute';
import { useAppSelector } from '../../config/store/hooks';
import {
  useCreateMappingMutation,
  useGetMappedAttributesQuery,
  useGetStaticAttributesQuery,
} from '../../config/api/mapperEndpoints';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StaticAttribute as StaticAttributeComponent } from './components/StaticAttribute';
import { Mapper } from '../../types/Mapper';
import { useSnackbar } from 'notistack';
import { useModalVisibility } from '../../hooks';
import { ReviewModal } from './components/ReviewModal';
import { MappingState } from '../../types/MappingState';
import { RequestModal } from './components/RequestModal';
import Navbar from '../../components/Navbar/Index';
import { attributesToIgnore } from './AttributesToIgnore';
import { UserClaims } from '../../types/UserClaims';
import { MappedAttribute } from '../../types/MappedAttribute';
import { Skeleton } from '@material-ui/lab';
import { SecurityCodeModal } from '../../components/SecurityCodeModal/SecurityCodeModal';
import { IdpAttributes } from '../../types/IdpUser';

const MapAttributes = () => {
  const userClaims = useAppSelector(state => state.user.claims);
  const { enqueueSnackbar } = useSnackbar();
  const [createMapping, createMappingResult] = useCreateMappingMutation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isWrongSecurityCode, setIsWrongSecurityCode] = useState<boolean>(false);
  const validationModalState = useModalVisibility();
  const securityCodeModalState = useModalVisibility();
  const requestModalState = useModalVisibility();
  const { details: federatedUserDetails } = useAppSelector(state => state.user);

  const {
    data: staticAttributes,
    isSuccess,
    isLoading
  } = useGetStaticAttributesQuery();
  const [mappingState, setMappingState] = useState<MappingState>({
    staticAttributes: {},
    idpAttributes: {},
    isInitialized: false
  });
  const mappedAttributesQuery = useGetMappedAttributesQuery();

  const getIdpAttributeValue = useCallback((key: keyof UserClaims): string | null => {
    if (userClaims === null) return null;

    let value = userClaims[key];

    if (value === undefined) return null;

    if (Array.isArray(value)) {
      if (value.length === 0) return null;

      value = value[0].toString();
    }

    return value.toString();
  }, [userClaims]);

  const allIdpAttributesLabels = useMemo(() => {
      return Object.entries(userClaims || {}).map(([key]) => key).filter(key => {
        return !attributesToIgnore.includes(key);
      });
    },
    [userClaims]);

  const allIdpAttributes: IdpAttributes = useMemo(() => {
    const attributes: IdpAttributes = {};

    allIdpAttributesLabels.forEach(key => attributes[key] = getIdpAttributeValue(key as keyof UserClaims) ?? '');

    return attributes;
  }, [allIdpAttributesLabels, getIdpAttributeValue]);

  /**
   * Effect to handle the result of the createMapping mutation.
   * */
  useEffect(() => {
    if (createMappingResult.isLoading) {
      setHasError(false);
      setErrorMessage('');
      requestModalState.openModal();
    }
    if (createMappingResult.isSuccess) {
      setIsWrongSecurityCode(false);
    }
    if (createMappingResult.isError) {
      setHasError(true);
      if ('status' in createMappingResult.error && createMappingResult.error.status === 403) 
        setErrorMessage('Código de segurança inválido');
      else
        setErrorMessage('Ocorreu um erro ao realizar o mapeamento, tente novamente mais tarde.');
    }
  }, [createMappingResult.error, createMappingResult.isError, createMappingResult.isLoading, createMappingResult.isSuccess, requestModalState]);

  const getMappedValues = (mappedAttribute: MappedAttribute): string[] => {
    return mappedAttribute.mappedValue.split(';');
  };

  /**
   * Effect to initialize the mappingState object.
   * */
  useEffect(() => {
    if (!isLoading && isSuccess && staticAttributes && !mappedAttributesQuery.isLoading && mappedAttributesQuery.isSuccess) {
      const exceptionalRequiredStaticAttributeCount = 1;
      const requiredStaticAttributes = staticAttributes.filter(attribute => attribute.required);
      const mappedAttributes = mappedAttributesQuery.data.attributes;
      const newMappingState: MappingState = {
        idpAttributes: {},
        staticAttributes: {},
        isInitialized: true
      };

      staticAttributes.forEach(attribute => {
        const mappedValues = mappedAttributes.filter(mappedAttribute => mappedAttribute.id === attribute.id)
          .map(getMappedValues).flat().filter(value => allIdpAttributesLabels.includes(value));
        newMappingState.staticAttributes[attribute.id] = {
          hasError: false,
          required: attribute.required,
          multiValue: attribute.multiValue,
          value: mappedValues,
          errorMessage: ''
        };
      });

      allIdpAttributesLabels.forEach(key => {
        const usedBy = mappedAttributes.filter(mappedAttribute =>
          getMappedValues(mappedAttribute).includes(key)).map(mappedAttribute => mappedAttribute.id);
        newMappingState.idpAttributes[key] = {
          inUse: usedBy.length > 0,
          useBy: usedBy
        };
      });

      if (allIdpAttributesLabels.length < requiredStaticAttributes.length - exceptionalRequiredStaticAttributeCount) {
        enqueueSnackbar('Não existem atributos suficientes para mapear! Contate o administrador!', {
          variant: 'warning',
          persist: true,
        });
      }

      setMappingState(newMappingState);
    }
  }, [enqueueSnackbar, allIdpAttributesLabels, isLoading, isSuccess, mappedAttributesQuery.data?.attributes,
    mappedAttributesQuery.isLoading, mappedAttributesQuery.isSuccess, staticAttributes, userClaims]);

  const existsIdpAttribute = (name: string) => {
    return mappingState.idpAttributes[name] !== undefined;

  };

  const alreadyInUse = useCallback((name: string) => {
    if (mappingState.idpAttributes[name] !== undefined && mappingState.idpAttributes[name].inUse)
      /* MultiValue attributes can be used by multiple static attributes */
      for (const staticAttributeId of mappingState.idpAttributes[name].useBy)
        if (!mappingState.staticAttributes[staticAttributeId].multiValue) return true;
    return false;
  }, [mappingState.idpAttributes, mappingState.staticAttributes]);
  const removeMapping = (mappingState: MappingState, staticAttributeId: string, value: string) => {
    mappingState.idpAttributes[value].useBy = mappingState.idpAttributes[value].useBy.filter(id => id !== staticAttributeId);
    mappingState.idpAttributes[value].inUse = mappingState.idpAttributes[value].useBy.length > 0;

  };
  const addMapping = (mappingState: MappingState, staticAttributeId: string, value: string) => {
    mappingState.idpAttributes[value].useBy.push(staticAttributeId);
    mappingState.idpAttributes[value].inUse = true;

  };
  const handleChangeStaticAttribute = (newValue: string[] | string, oldValue: string[] | string, staticAttributeId: string) => {
    //TODO: verificar meio para evitar o reset
    createMappingResult.reset();
    const attributeIsMultiValue = mappingState.staticAttributes[staticAttributeId].multiValue;

    const valuesAreArrays = Array.isArray(newValue) && Array.isArray(oldValue);
    if (attributeIsMultiValue && valuesAreArrays) {
      return handleChangeStaticAttributeMultiValue(newValue as string[], oldValue as string[], staticAttributeId);

    }
    if (!attributeIsMultiValue) {
      return handleChangeStaticAttributeSingleValue(newValue.toString(), oldValue.toString(), staticAttributeId);

    }
    return false;

  };
  const updateStaticAttributeState = (mappingState: MappingState, staticAttributeId: string, permittedChange: boolean,
                                      newValues: string[], oldValues: string[], errorMessage: string | null) => {
    mappingState.staticAttributes[staticAttributeId] = {
      ...mappingState.staticAttributes[staticAttributeId],
      value: permittedChange ? newValues : oldValues,
      hasError: errorMessage !== null,
      errorMessage: errorMessage ?? ''
    };

  };
  const handleChangeStaticAttributeSingleValue = (newValue: string, oldValue: string, staticAttributeId: string) => {
    const newMappingState = structuredClone(mappingState) as MappingState;
    const staticAttributeState = newMappingState.staticAttributes[staticAttributeId];
    let errorMessage = null;

    let permittedChange = true;
    if (newValue === '') {
      if (existsIdpAttribute(oldValue)) removeMapping(newMappingState, staticAttributeId, oldValue);
      if (staticAttributeState.required) errorMessage = 'Atributo é obrigatório!';
      permittedChange = true;
    } else if (alreadyInUse(newValue)) {
      enqueueSnackbar('Atributo já está sendo usado!', { variant: 'warning' });
      permittedChange = false;
    } else {
      addMapping(newMappingState, staticAttributeId, newValue);
      if (existsIdpAttribute(oldValue)) removeMapping(newMappingState, staticAttributeId, oldValue);

    }
    updateStaticAttributeState(newMappingState, staticAttributeId, permittedChange, [newValue], [oldValue], errorMessage);
    setMappingState(newMappingState);
    return permittedChange;

  };
  const handleChangeStaticAttributeMultiValue = (newValues: string[], oldValues: string[], staticAttributeId: string) => {
    const newMappingState = structuredClone(mappingState) as MappingState;
    let errorMessage = null;

    let permittedChange = true;
    if (newValues.length > oldValues.length) {
      // adding new entry
      const newValueToAdd = newValues[newValues.length - 1];
      addMapping(newMappingState, staticAttributeId, newValueToAdd);
    } else if (newValues.length < oldValues.length) {
      // removed an entry
      oldValues.filter(v => !newValues.includes(v)).forEach((removedValue) => {
        removeMapping(newMappingState, staticAttributeId, removedValue);

      });
      if (newValues.length === 0 && mappingState.staticAttributes[staticAttributeId].required) {
        errorMessage = 'Atributo é obrigatório';
        permittedChange = true;
      }

    }
    updateStaticAttributeState(newMappingState, staticAttributeId, permittedChange, newValues, oldValues, errorMessage);

    setMappingState(newMappingState);
    return permittedChange;

  };


  const isReadyToPresent = () => !isLoading && isSuccess && mappingState.isInitialized && staticAttributes;


  const hasErrorInAnyStaticAttributes = useMemo(() => {
    return Object.values(mappingState.staticAttributes).some(attribute => attribute.hasError ||
      (attribute.value.length === 0 && attribute.required));
  }, [mappingState.staticAttributes]);
  const handleConfirmAttributes = () => {
    if (hasErrorInAnyStaticAttributes) {
      validationModalState.closeModal();
      setHasError(true);
      setErrorMessage('Existem atributos inválidos');
      return;
    }

    securityCodeModalState.openModal();
  };

  const handleConfirmSecurityCode = (securityCode: string) => {
    const mapping: { [key: string]: string[] } = {};
    Object.entries(mappingState.staticAttributes).filter(([, attribute]) => !(attribute.value.length === 0
      && !attribute.required)).forEach(([id, attribute]) => {
      mapping[id] = attribute.value;
    });

    const payload: Mapper = {
      idpId: federatedUserDetails?.identityProvider.id ?? '',
      attributes: mapping
    };

    createMapping([payload, securityCode]).unwrap()
      .then(() => {
        validationModalState.closeModal();
        securityCodeModalState.closeModal();
      })
      .catch((e) => {
        if (e.status === 403) {
          requestModalState.closeModal();
          setIsWrongSecurityCode(true);
          setErrorMessage('Código de segurança inválido');
        }
      });
  };

  const lastUpdate = useMemo(() => {
    return mappedAttributesQuery.data?.lastUpdate;
  }, [mappedAttributesQuery.data?.lastUpdate]);

  const idpAttributesUsedLabels = useMemo(() => {
    return Object.keys(mappingState.idpAttributes).filter((key) => alreadyInUse(key));
  }, [alreadyInUse, mappingState.idpAttributes]);

  const requiredAttributes = useMemo(() => {
    return staticAttributes?.filter(a => a.required) || [];
  }, [staticAttributes]);
  const optionalAttributes = useMemo(() => {
    return staticAttributes?.filter(a => !a.required && !a.name.includes('SOCIAL_NAME')) || [];
  }, [staticAttributes]);
  const socialNameAttributes = useMemo(() => {
    return staticAttributes?.filter(a => !a.required && a.name.includes('SOCIAL_NAME')) || [];
  }, [staticAttributes]);

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <div style={{ padding: '2rem 0' }}>

            <div style={{ textAlign: 'center' }}>
              <h1>Mapear Atributos</h1>
              <p style={{ fontSize: '1.25rem', marginLeft: 'auto', marginRight: 'auto' }}>Faça o mapeamento associando
                os atributos fornecidos pela instituição (IDP) e os atributos obrigatórios e opcionais solicitados pela
                plataforma.</p>
              {lastUpdate &&
                <p style={{ fontSize: '1.25rem', marginLeft: 'auto', marginRight: 'auto', fontWeight: 'bold' }}>Última
                  alteração registrada em: {lastUpdate}.</p>}
            </div>

            <Grid container spacing={3}>

              <Grid item xs={12} md={4}>
                <h2 style={{ textAlign: 'center' }}>Atributos fornecidos pela instituição</h2>

                <Box>
                  {isReadyToPresent() ? <>
                    <FormControl fullWidth>
                      <Atributo>
                        <TextField label='Pesquisar atributos' variant='filled' value={searchTerm}
                                   onChange={e => setSearchTerm(e.target.value)} />
                      </Atributo>
                    </FormControl>

                    <Atributos>
                      {Object.entries(allIdpAttributes).filter(([key]) =>
                        searchTerm === '' || key.toLowerCase().includes(searchTerm.toLowerCase())).map(([key, value]) =>
                        <Attribute
                          key={key} active={mappingState.idpAttributes[key].inUse} name={key} value={value}
                          icon={mappingState.idpAttributes[key].inUse ? <FaCheck /> : <FaWindowClose />}
                        />)}
                    </Atributos>
                  </> : <>
                    <Skeleton variant='rect' height={50} />
                    <br />
                    <Skeleton variant='rect' height={350} />
                  </>}

                </Box>

              </Grid>

              <Grid item xs={12} md={8}>
                <h2 style={{ textAlign: 'center' }}>Mapeador de atributos da plataforma</h2>
                <Box>
                  {isReadyToPresent() ?
                    <>
                  <h3>Atributos obrigatórios</h3>
                  <hr style={{ backgroundColor: '#0C326F', height: '3px', margin: '1rem 0 2rem' }} />

                      <Grid container spacing={4} style={{ marginBottom: '2rem' }}>
                        {requiredAttributes.map(attribute =>
                      <StaticAttributeComponent key={attribute.id}
                                                id={attribute.id}
                                                usedValues={idpAttributesUsedLabels}
                                                allValues={allIdpAttributesLabels}
                                                name={attribute.name}
                                                hasError={mappingState.staticAttributes[attribute.id].hasError}
                                                required={mappingState.staticAttributes[attribute.id].required}
                                                errorMessage={mappingState.staticAttributes[attribute.id].errorMessage}
                                                isMultiValued={mappingState.staticAttributes[attribute.id].multiValue}
                                                initialValue={mappingState.staticAttributes[attribute.id].value}
                                                onChange={handleChangeStaticAttribute}
                      />)}

                  </Grid>
                      <h3 style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        Atributos recomendados
                        <Tooltip title={'Estes atributos só serão validados se definidos.'}>
                            <span style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                              <FaRegQuestionCircle size={18} style={{ color: '#0C326F' }} />
                            </span>
                        </Tooltip>
                      </h3>
                      <hr style={{ backgroundColor: '#0C326F', height: '3px', margin: '1rem 0 2rem' }} />
                      <Grid container spacing={4} style={{ marginBottom: '2rem' }}>
                        {socialNameAttributes.map(attribute =>
                          <StaticAttributeComponent
                            key={attribute.id}
                            id={attribute.id}
                            usedValues={idpAttributesUsedLabels}
                            allValues={allIdpAttributesLabels}
                            name={attribute.name}
                            hasError={mappingState.staticAttributes[attribute.id].hasError}
                            required={mappingState.staticAttributes[attribute.id].required}
                            errorMessage={mappingState.staticAttributes[attribute.id].errorMessage}
                            isMultiValued={mappingState.staticAttributes[attribute.id].multiValue}
                            initialValue={mappingState.staticAttributes[attribute.id].value}
                            onChange={handleChangeStaticAttribute}
                          />)}
                      </Grid>
                      
                  <h3>Atributos opcionais</h3>
                  <hr style={{ backgroundColor: '#0C326F', height: '3px', margin: '1rem 0 2rem' }} />
                  <Grid container spacing={4}>

                    {optionalAttributes.map(attribute =>
                      <StaticAttributeComponent
                        key={attribute.id}
                        id={attribute.id}
                        usedValues={idpAttributesUsedLabels}
                        allValues={allIdpAttributesLabels}
                        name={attribute.name}
                        hasError={mappingState.staticAttributes[attribute.id].hasError}
                        required={mappingState.staticAttributes[attribute.id].required}
                        errorMessage={mappingState.staticAttributes[attribute.id].errorMessage}
                        isMultiValued={mappingState.staticAttributes[attribute.id].multiValue}
                        initialValue={mappingState.staticAttributes[attribute.id].value}
                        onChange={handleChangeStaticAttribute}
                      />)}

                  </Grid>
                    </>
                    :
                    <>
                      <Skeleton variant='rect' height={450} />
                      <br />
                      <br />
                      <Skeleton variant='rect' height={250} />
                    </>
                  }
                </Box>

                {isReadyToPresent() && <Actions>
                  {hasError && <div style={{ color: 'red' }}><strong>{errorMessage}</strong></div>}
                  {createMappingResult.isLoading && <div style={{ color: '#aaa' }}>Mapeamento enviado, aguarde...</div>}
                  {createMappingResult.isSuccess &&
                    <div style={{ color: 'green' }}><strong>Mapeamento realizado com sucesso!</strong></div>}
                  <div style={{ textAlign: 'end' }}>
                    <Button size='large'
                            disabled={hasErrorInAnyStaticAttributes || createMappingResult.isLoading || createMappingResult.isSuccess}
                            onClick={() => validationModalState.openModal()}>
                      Salvar
                    </Button>
                  </div>
                </Actions>}


              </Grid>
            </Grid>
          </div>
          {isReadyToPresent() && <ReviewModal
            open={validationModalState.isVisible}
            handleClose={validationModalState.closeModal}
            handleConfirm={handleConfirmAttributes}
            staticAttributes={staticAttributes || []}
            mapping={mappingState}
            attributesFromIdp={allIdpAttributes}
          />}
          <RequestModal
            open={requestModalState.isVisible}
            requestMutation={createMappingResult}
            handleClose={requestModalState.closeModal}
          />
          <SecurityCodeModal
            open={securityCodeModalState.isVisible}
            handleConfirm={handleConfirmSecurityCode}
            isWrongSecurityCode={isWrongSecurityCode}
            handleClose={() => {
              securityCodeModalState.closeModal();
              setIsWrongSecurityCode(false);
            }}
          />
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default MapAttributes;